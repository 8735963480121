import { useEffect, useMemo } from 'react';

import {
	type BaseAIAction,
	type BaseAISmartButtonContext,
	useAISmartButton,
} from '../../ai-smart-button-store';

/**
 * Hook for managing actions associated with a specific source.
 * Automatically handles cleanup on unmount.
 */
export const useSourceAIActions = (sourceId: string) => {
	const [, aiActions] = useAISmartButton();

	useEffect(() => {
		// Cleanup on unmount.
		return () => {
			aiActions.removeAll(sourceId);
			aiActions.removeContext(sourceId);
		};
	}, [sourceId, aiActions]);

	return useMemo(
		() => ({
			/**
			 * Replaces all actions for this source with new ones.
			 */
			setActions: (actions: BaseAIAction[]) => {
				aiActions.setActions(sourceId, actions);
			},

			/*
			 * Replaces all nudges for this source with new ones.
			 */
			setNudges: (nudges: BaseAIAction[]) => {
				aiActions.setNudges(sourceId, nudges);
			},

			/**
			 * Replaces all suggested actions for this source with new ones.
			 */
			setSuggestedActions: (suggestedActions: BaseAIAction[]) => {
				aiActions.setSuggestedActions(sourceId, suggestedActions);
			},

			/**
			 * Sets context for this source.
			 */
			setContext: (context: BaseAISmartButtonContext) =>
				aiActions.setContext({ ...context, sourceId }),

			/**
			 * Removes context for this source.
			 */
			removeContext: () => aiActions.removeContext(sourceId),

			/**
			 * Removes all actions for this source.
			 */
			removeAll: () => aiActions.removeAll(sourceId),
		}),
		[sourceId, aiActions],
	);
};

export type SourceAIActions = ReturnType<typeof useSourceAIActions>;
